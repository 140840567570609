.result {
  display: grid;
  width: 100%;

  /* box-shadow: rgb(0 0 0 / 0%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 18px 10px -12px; */
}

.result img {
  border-radius: 6px;
  border: 4px solid transparent;
  max-width: 100% !important;
  object-fit: contain;
  transition: 0.7s;
}

.result img:hover {
  transform: scale(1.02);
  cursor: pointer;
  border: 4px solid white;
}

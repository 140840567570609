.originalsTitle {
  padding: 60px 0px 60px 0px;
  text-align: center;
  text-transform: uppercase;
  font-family: Avenir-heavy;
  font-size: 2.7rem;
  letter-spacing: 1rem;
  /* margin-top: 60px; */
}

#originals {
  transition: 0.5s;
}

.originalsList h4 {
  font-weight: 200;
  font-family: Avenir-Heavy, sans-serif;
  font-size: 20px;
  padding-left: 5px;
}

.recommendationSection {
  margin: 20px 0px 20px 30px;
}

.fixedBannerOriginals {
  padding-left: 0px !important;
  padding-bottom: 0px;
  z-index: 5;
  position: fixed;
  background-color: rgb(26, 29, 41);
  top: 0px;
  width: 100%;
  padding: 60px 0px 0px 50px;
}

.page2 {
  margin: 0;
}

.originalsList {
  /* padding-left: 30px;
  padding-top: 230px; */
  padding: 250px 50px 100px 50px;
}

@media only screen and (max-width: 765px) {
  .originalsList {
    padding-left: 20px;
    padding-right: 20px;
  }
  .originalsTitle {
    padding-top: 150px;
    font-size: 2rem;
    letter-spacing: 0.7rem;
  }
}

.banner {
  max-width: 97%;
  display: grid !important;
  width: 100%;
  outline: none !important;
  transition: 0.3s;
  border-radius: 7px;
  border: 3px solid transparent;
  box-sizing: border-box;
  margin-bottom: 25px;
  /* box-shadow: rgb(0 0 0 / 0%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 10px 20px 20px -10px; */
}

.banner:hover {
  border-radius: 7px;
  border: 3px solid rgba(255, 255, 255, 0.8);
}

.banner img {
  border-radius: 4px;
  max-width: 100% !important;
  object-fit: contain;
  outline: none !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 10px 20px 20px -10px;
}

.slick-dots li.slick-active button:before {
  opacity: 0.35 !important;
  color: white !important;
}

.slick-dots li button:before {
  opacity: 1 !important;
  color: white !important;
}

.extrasGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding-top: 20px;
}

.extrasGrid img {
  width: 100%;
  max-height: 137px;
  object-fit: cover;
}

.extrasVid {
  cursor: pointer;
  margin-bottom: 5px;
}

.extrasGrid h6 {
  max-width: 96%;
  margin: auto;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}

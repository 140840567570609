.logo {
  width: 70%;
  margin: auto;
  margin-left: 0;
}

.logo img {
  width: 100px !important;
}

.nav {
  padding: 0px 30px;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  grid-template-columns: 1fr 5fr 1fr;
  column-gap: 30px;
  align-items: center;
  text-align: center;
  font-size: 0.85rem;
  padding-bottom: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.03) 15%,
    rgba(0, 0, 0, 0.125) 30%,
    rgba(0, 0, 0, 0.25) 46%,
    rgba(0, 0, 0, 0.4) 61%,
    rgba(0, 0, 0, 0.553) 75%,
    rgba(0, 0, 0, 0.694) 88%,
    rgba(0, 0, 0, 0.8)
  );
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: 0.3s;
  width: -webkit-fill-available;
}

.menu {
  color: white;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.menu li {
  text-transform: uppercase;
  list-style-type: none;
  cursor: pointer;
  width: min-content;
  letter-spacing: 2px;
  border-bottom: 1px solid transparent;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 8px;
  padding: 15px;
}

.menu li a {
  text-decoration: none;
}

.menu li svg {
  align-self: baseline;
  font-size: 1rem;
}

.menu span:after {
  display: block;
  padding-bottom: 4px;
  content: "";
  border-bottom: solid 2px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.menu span:hover:after {
  transform: scaleX(1);
}

.menu span:after {
  transform-origin: 0% 50%;
}

.login {
  text-decoration: none;
  cursor: pointer;
  color: white;
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  /* align-items: center; */
  display: inline-block;
  margin: auto;
}

.login p {
  /* justify-self: end; */
  padding-right: 7px;
  font-weight: 200;
  float: left;
  margin: 0;
  margin-top: 7px;
}

.login svg {
  float: left;
  font-size: 2.2rem;
}

@media only screen and (max-width: 1075px) {
  .nav {
    font-size: 0.65rem;
    padding: 0px 15px;
    column-gap: 0px;
    padding: 15px;
  }

  .menu li svg {
    font-size: 0.65rem;
  }

  .menu {
    padding-inline-start: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    padding: 0 40px;
  }

  .menu li {
    padding: 5px;
    /* margin: auto; */
  }
  .logo {
    width: 100%;
  }

  .login svg {
    font-size: 1.95rem;
  }
}

@media only screen and (max-width: 765px) {
  .nav {
    font-size: 0.5rem;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 70px 30px;
    padding-top: 10px;
    padding: 0px 10px 10px 10px;
    position: absolute !important;
  }

  .login {
    grid-template-columns: 1.5fr 1fr;
  }
  /*
  .menu li svg {
    font-size: 0.5rem;
  } */

  .logo {
    width: 20%;
    width: auto;
    margin: auto;
    padding-top: 10px;
  }

  .logo img {
    width: 60%;
  }

  .menu {
    justify-items: center;
  }

  .login svg {
    font-size: 1.5rem;
  }
}

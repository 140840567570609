/* General */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");
* {
  font-family: "Nunito Sans";
  color: white;
  color: rgb(249, 249, 249);
}

body {
  margin: 0;
}

html {
  background-color: rgb(26, 29, 41);
}
.App {
  padding-top: 50px;
}

.wrapper {
  padding-top: 50px;
}

@media only screen and (max-width: 1050px) {
}

h3 {
  font-size: 20px;
  font-family: Avenir-Heavy, sans-serif;
}

/* Page Template */
.page {
  margin: 50px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 765px) {
  .wrapper {
    padding-top: 100px;
  }
  .App {
    padding-top: 100px;
  }
  .page {
    margin: 20px;
    margin-top: 0px;
  }
}

.pageTitle {
  font-family: Avenir-Heavy, sans-serif;
  font-size: 3rem;
  font-weight: 600;
  font-size: clamp(2rem, 1.6667rem + 1.4815vw, 3rem);
}

.watchlistWrapper {
  margin-top: 80px;
  display: grid;
  text-align: center;
  justify-self: center;
  font-weight: 200;
}
.watchlistWrapper h3 {
  margin-bottom: 0;
  font-size: 1.4rem;
}

.watchlistWrapper svg {
  fill: rgb(255, 255, 255, 0.2);
  font-size: 9rem;
  justify-self: center;
}
.wrapper {
  margin-bottom: 100px;
}

@media only screen and (max-width: 765px) {
  .watchlistWrapper {
    margin-top: 40px;
  }
  .watchlistWrapper svg {
    font-size: 5rem;
  }

  .wrapper {
    margin-bottom: 60px;
  }
}

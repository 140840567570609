.input {
  width: 100%;
  width: -webkit-fill-available;
  height: 60px;
  padding: 20px;
  padding-left: 50px;
  font-size: 2.5rem;
  font-size: clamp(1rem, 0.5rem + 2.2222vw, 2.5rem);
  background: rgb(75, 78, 90);
  border: none;
  color: rgb(249, 249, 249);
  font-family: avenir-heavy;
  letter-spacing: 1.17px;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.input:focus {
  background: rgb(98, 102, 118);
  outline: none;
  color: white;
}
.input::placeholder {
  color: rgb(255, 255, 255, 0.5);
}
.input:focus::placeholder {
  color: rgb(255, 255, 255, 0.8);
}

.results {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  gap: 25px;
}

.page {
  margin: 50px;
  margin-bottom: 100px;
}

.noResults em {
  font-weight: 400 !important;
}
.noResults em {
  color: #88b2f3;
  /* text-decoration: underline; */
  font-weight: 700 !important;
}

@media only screen and (max-width: 1050px) {
  .input {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 765px) {
  .input {
    padding: 0px 20px;
    /* padding-left: 20px; */
  }

  .page {
    margin: 50px 20px 100px !important;
  }

  .results {
    grid-template-columns: repeat(3, 1fr);
  }
}

.posterBackground {
  width: 100%;
  position: fixed;
  z-index: -1;
}

.resultBackground {
  /* z-index: 3; */
  /* background-color: #1a1d29; */
  /* opacity: 0.85; */
  /* height: 100vh; */
  height: auto;
  max-height: 100%;
  object-fit: cover;
  background-image: radial-gradient(
    farthest-side at 100% 21%,
    transparent,
    rgb(26, 29, 41)
  );

  /* background-image: radial-gradient(
    ellipse closest-side at 75% 19%,
    transparent -10%,
    #1a1e29 200%
  ); */
}

.resultPage {
  margin-top: -50px;

  /* margin-bottom: 50px; */
}

.resultInfo {
  /* position: relative; */
  /* position: absolute; */
  /* z-index: 15; */
  /* padding: 100px 250px 50px 70px; */
  padding: 100px 150px 50px 70px;
  /* opacity: 1 !important;  */
}

.opacity {
  height: 100vh;
  background-color: #1a1d29;
  opacity: 0.2;
  transition: opacity 200ms ease 0s;
  /* position: absolute; */
  /* background-color: yellow; */
}

.resultInfo h1 {
  font-size: 85px;
  max-width: 75%;
}

.overview {
  max-width: 80%;
}

.resultInfo h5 {
  font-size: 20px;
  margin-top: 15px;
  font-weight: 300;
}

.actions {
  display: inline-block;
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  width: 40%;
}

.play {
  text-decoration: none;
  cursor: pointer;
  color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  background: white;
  border-radius: 7px;
  width: 120px;
  padding: 5px 25px 5px 10px;
  float: left;
  border: 2px solid white;
  transition: 0.3s;
}

.play:hover {
  background: rgb(198, 198, 198);
  border: 2px solid rgb(198, 198, 198);
}

.play p {
  /* padding-left: 12px; */
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 15px;
  color: black;
}

.play svg {
  font-size: 20px;
  fill: black;
  justify-self: end;
  padding-right: 15px;
}

.trailer {
  background: black;
  width: 160px;
  border: 2px solid white;
  margin-left: 30px;
  margin-right: 30px;
}

.trailer:hover {
  background: rgba(249, 249, 249, 0.4);
  border: 2px solid rgba(249, 249, 249, 0) !important;
}

.trailer p {
  color: white;
}

.trailer svg {
  fill: white;
  padding-right: 10px;
}

.circleButton {
  display: grid;
  width: 60px;
  height: 62px;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  font-size: 2rem;
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 30px;
  transition: 0.3s;
}

.circleButton:hover {
  background: white;
}

.circleButton:hover svg {
  fill: black;
}

.trailerError {
  background-color: red;
  max-width: max-content;
  padding: 5px 10px;
  grid-template-columns: 1fr !important;
  border-color: red;
}

.trailerError:hover {
  background-color: red;
  border-color: red;
  cursor: default;
  cursor: not-allowed;
}

.playError {
  width: 150px;
}

.playError p {
  color: white;
}

.subNav {
  display: inline-block;
  width: 100%;
  border-bottom: 2px solid rgba(249, 249, 249, 0.2);
  /* padding-bottom: 20px; */
}
.subNav button {
  outline: none !important;
}

.subNavSection {
  float: left;
  margin-right: 25px;
  border-bottom: 3px solid transparent !important;
}

.subNavSection button {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  background-color: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-video-close-btn {
  cursor: pointer;
}

.generalInfo {
  padding: 20px 0px;
}

@media only screen and (max-width: 765px) {
  .resultPage {
    width: auto;
    padding-top: 80px;
  }

  .resultBackground {
    /* background: radial-gradient(
      ellipse closest-side at 75% 9%,
      transparent -10%,
      #1a1e29 200%
    ); */

    /* background-image: radial-gradient(
      ellipse closest-side at 55% 9%,
      transparent 60%,
      #1a1e29 200%
    ); */

    background: radial-gradient(
      ellipse closest-side at 70% 8%,
      transparent -10%,
      #1a1e29 200%
    ) !important;
  }

  .resultInfo {
    padding: 5px 30px !important;
  }

  .actions {
    /* grid-template-columns: 1fr 1fr; */
    /* grid-template-rows: 1fr 1fr;  */
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    justify-items: center;
  }

  .resultInfo h1 {
    font-size: 40px;
    max-width: 90%;
    margin: 40px 0px 50px 0px;
  }

  .play {
    max-width: 75px;
    /* height: 40px; */
  }

  .play p {
    font-size: 10px;
  }

  .play svg {
    font-size: 15px;
    padding-right: 0;
    padding-right: 5px !important;
  }

  .resultPage {
    width: fit-content;
    margin-top: 0;
    width: initial !important;
  }

  .resultInfo {
    padding-right: 60px !important;
    padding-right: 30px !important;
  }

  .circleButton {
    width: 40px;
    font-size: 1.3rem;
    height: 42px;
    margin-right: 0;
    margin-right: 10px;
  }

  .resultInfo h5 {
    font-size: 15px;
  }

  .trailer {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 100px !important;
  }

  .generalInfo {
    font-size: small;
  }

  .overview {
    max-width: 100% !important;
  }

  .subNavSection {
    margin-right: 10px;
  }

  .subNavSection button {
    font-size: 12px;
  }

  .extrasGrid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .extras {
    grid-template-columns: auto !important;
    grid-template-rows: 1fr 1fr !important;
  }

  .column2,
  .column3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 20px;
  }
}

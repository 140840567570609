.column1 h5 {
  margin-top: 0px;
  font-weight: 300;
}

.column2 div {
  padding-bottom: 10px;
}

.column3 div {
  padding-bottom: 10px;
}

.extras {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 30px;
}

.extras p {
  font-size: 15px;
  font-weight: 300;
  margin: 0;
}

.extras p:first-child {
  color: rgb(202, 202, 202);
}

.cast {
  list-style: none;
}

.subNavSection button {
  padding-bottom: 20px;
  transition: 0.3s;
}

.active {
  border-bottom: 3px solid white !important;
  transition: 0.3s;
}
